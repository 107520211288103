// App.js
import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import "./App.css";

const steps = [
  {
    icon: "🌱",
    title: "Inspiration",
    description: "Trouvez l'idée qui mérite de traverser le temps."
  },
  {
    icon: "📸",
    title: "Création",
    description: "Ajoutez vos souvenirs : texte, audio, vidéo, image."
  },
  {
    icon: "⏰",
    title: "Choisissez une date",
    description: "Programmez l'ouverture de votre capsule."
  },
  {
    icon: "🔒",
    title: "Sécurisation",
    description: "Capsule scellée. Elle attend patiemment son heure."
  }
];

const App = () => {
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  return (
    <div className="app-container">
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          fullScreen: { enable: true, zIndex: -1 },
          background: { color: "transparent" },
          particles: {
            number: { value: 50 },
            size: { value: 2 },
            move: { direction: "bottom", speed: 0.8 },
            color: { value: "#f5d742" },
            opacity: { value: 0.7 },
          },
        }}
      />
      <div className="content">
        <h1 className="title">Capsy</h1>
        <p className="subtitle">Voyagez dans le temps avec vos souvenirs</p>
        <div className="timeline">
          {steps.map((step, index) => (
            <div key={index} className="step">
              <div className="icon">{step.icon}</div>
              <h3>{step.title}</h3>
              <p>{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;
